import { executeNetworkRequestWithAuth } from './../common/NetworkBuisness.js';
import { tickets, usersState, settoriState, notificationState } from "../common/Store.js";
import {
    useSetRecoilState,
    useRecoilValue,
} from 'recoil';
import { notify_loading, notify_loading_finish } from "../common/Toast";


export function useTask() {
    const task = useRecoilValue(tickets);
    const setTask = useSetRecoilState(tickets);
    const settori = useRecoilValue(settoriState);
    const setSettori = useSetRecoilState(settoriState);

    return { fetchTasks, getTask };

    async function fetchTasks() {
        const id_notify = notify_loading("Caricamento ticket");
        const settori_fetch = await executeNetworkRequestWithAuth("get", "/settores", []);
        setSettori(settori_fetch.data);
        const user = JSON.parse(localStorage.getItem("user"));
        if (!user) {
            notify_loading_finish(id_notify, "Errore nel caricamento ticket");
            return;
        }
        const settori_fetch_filtered = settori_fetch?.data?.filter(settore => settore.partecipanti.some(partecipante => partecipante.id == user.user.id));
        const role = user.user.role.type;
        let url;
        let task_fetch_capo_area = [];
        let task_settore = [];
        let task_settore_all = [];

        for (let settore of settori_fetch_filtered) {
            url = `/tickets?_sort=id:DESC&settore=${settore.id}`;
            task_settore = await executeNetworkRequestWithAuth("get", url, []);
            task_settore_all = [...task_settore_all, ...task_settore.data];
        }

        switch (role) {
            case "authenticated":
                url = `/tickets?_sort=id:DESC`;
                break;
            case "public":
                url = `/tickets?_sort=id:DESC&associato_as_contains=${user.user.id}`;
                task_fetch_capo_area = await executeNetworkRequestWithAuth("get", url, []);
                url = `/tickets?_sort=id:DESC&settore=${user.user.settore.id}`;
                break;
            case "operatore":
                url = `/tickets?_sort=id:DESC&associato_as_contains=${user.user.id}`;
                break;
            default:
                url = `/tickets?_sort=id:DESC&associato_as_contains=${user.user.id}`;
                break;
        }
        const task_fetch = await executeNetworkRequestWithAuth("get", url, []);

        let unique;

        if (task_fetch_capo_area.data?.length > 0) {
            unique = [...new Map([...task_fetch_capo_area.data, ...task_settore_all, ...task_fetch.data].map(item => [item["id"], item])).values()];
        }
        else { unique = [...new Map([...task_settore_all, ...task_fetch.data].map(item => [item["id"], item])).values()]; }
        task_fetch_capo_area.data?.length > 0 ? setTask(unique) : setTask(unique);
        notify_loading_finish(id_notify, "Caricamento ticket riuscito");
        return true;
    }

    async function getTask() {
        fetchTasks();
        return true;
    }
}

export function useUser() {
    const users = useRecoilValue(usersState);
    const setUsers = useSetRecoilState(usersState);

    return { getUsers };

    async function getUsers() {
        const users_fetch = await executeNetworkRequestWithAuth("get", "/users", []);
        setUsers(users_fetch.data);
        return true;
    }
}
export function useNotification() {
    const notification = useRecoilValue(notificationState);
    const setNotification = useSetRecoilState(notificationState);

    return { getNotification };

    async function getNotification() {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user) {
            const users_fetch = await executeNetworkRequestWithAuth("get", `/notificas?_limit=20&_sort=id:DESC&associato_as_contains=${user.user.id}`, []);
            setNotification(users_fetch.data);
            //notify_success("Caricate notifiche");
            return true;
        }
        return false;
    }
}
