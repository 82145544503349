import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Fragment, useState } from 'react'
import 'animate.css';
import { useHistory } from "react-router-dom"

import {
  RecoilRoot,
  useRecoilValue
} from 'recoil';

import Menu from './componets/menu.js';
import { ToastContainer } from 'react-toastify';
import { executeNetworkRequestWithAuth } from './common/NetworkBuisness.js';

import Login from "./pages/login.js";
import Task from "./pages/task.js";
import Home from "./pages/home.js";
import Grid from "./pages/grid.js";

import Dashboard from "./pages/dashboard.js";
import { useTask, useNotification } from "./common/HookStore.js";
import { notificationState } from "./common/Store.js";

const TopMenu = () => {
  const notificationHook = useNotification();
  const notification = useRecoilValue(notificationState);
  const [showNotification, setShowNotification] = useState(false);
  let history = useHistory()

  const readNotify = async (id, status) => {
    const result = await executeNetworkRequestWithAuth("put", `/notificas/${id}`, {
      letta: status
    });
    notificationHook.getNotification();
  }

  return <div className=" p-4 lg:p-6 px-4">
    <div className="grid grid-cols-2 pb-2">
      <div className="col-span-1 col-start-2 text-right">
        <span className="mr-5 text-gray-600">{JSON.parse(localStorage.getItem("user"))?.user?.username}</span>
        <img alt="avatar" className=" w-10 h-10 rounded-full	inline" src={process.env.REACT_APP_BACKEND + JSON.parse(localStorage.getItem("user"))?.user.immagine_profilo.url} />
        <div className={`${notification?.filter(notify => notify.letta == false).length != 0 ? "bg-red-500" : "bg-blue-500"} p-2 rounded-full cursor-pointer inline ml-4 relative`}>
          <div className="inline disable-select" onClick={(e) => { setShowNotification(!showNotification) }}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline" fill="none" viewBox="0 0 24 24" stroke="white">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
            </svg>
            <span className="ml-1 pr-2 text-white	">{notification?.filter(notify => notify.letta == false).length}</span>
          </div>
          {showNotification && <div className="animate__animated animate__fadeInDown animate__faster shadow-md" style={{
            position: "absolute",
            right: "10%",
            textAlign: "left",
            backgroundColor: "#f7f7f7",
            zIndex: "10000",
            padding: "12px",
            borderRadius: "15px"
          }}>
            <div style={{
              width: "320px",
              height: "450px",
              overflowY: "auto",
            }}>
              {[...notification].reverse().sort((a, b) => (a.letta > b.letta) ? 1 : -1)
                .map((noty) => <div className="pb-2 pt-2 bg-white p-2 mb-4 rounded-lg">
                  <p onClick={() => { history.push(`/ticket/${noty.ticket?.id}`); setShowNotification(false) }}>
                    {!noty.letta ? <svg xmlns="http://www.w3.org/2000/svg" className="h-2 w-2 inline mr-2" fill="red" viewBox="0 0 24 24" stroke="red">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg> : <svg xmlns="http://www.w3.org/2000/svg" className="h-2 w-2 inline mr-2" fill="blue" viewBox="0 0 24 24" stroke="blue">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    }
                    {noty.nome}</p>
                  {!noty.letta ?
                    <p onClick={() => readNotify(noty.id, true)} className="text-xs text-gray-500 underline">Letto</p>
                    : <p onClick={() => readNotify(noty.id, false)} className="text-xs text-gray-500 underline">Segna da leggere</p>}
                </div>)}
            </div>
          </div>}
        </div>
      </div>
    </div>
  </div>
}
const RefreshButton = () => {
  const [loading, setLoading] = useState(false);
  const TaskHook = useTask();
  const notificationHook = useNotification();
  return <div onClick={() => {
    notificationHook.getNotification();
    TaskHook.getTask();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }} className={loading ? "animate-spin bg-blue-500 p-2 rounded-full cursor-pointer" : "bg-blue-500 p-2 rounded-full cursor-pointer"} style={{ position: "fixed", bottom: "15px", right: "100px" }}>
    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="#fff">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
    </svg>
  </div>
}
function App() {
  return (
    <RecoilRoot>
      <Router>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Fragment>
            <div className="flex flex-col h-screen px-8 xl:px-28  ">
              <main className="flex flex-col h-screen ">
                <div className="flex  overflow-hidden w-full	">
                  <Menu />
                  <div className="flex flex-col ">
                    <TopMenu />
                    <Route exact path="/" component={Home} />
                    <Route exact path="/dashboard" component={Dashboard} />
                    <Route exact path="/ticket" component={Task} />
                    <Route exact path="/ticket/:idTicket" component={Task} />
                    <Route exact path="/grid" component={Grid} />
                  </div>
                </div>
              </main>
              <RefreshButton />
            </div >
          </Fragment>
        </Switch>
      </Router>
      <ToastContainer />
    </RecoilRoot>
  );
}

export default App;
