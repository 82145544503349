import { useState } from "react";
import {
    useRecoilValue,
} from 'recoil';
import { settoriState } from "../common/Store.js";

import { Bar } from 'react-chartjs-2';
import { Doughnut } from 'react-chartjs-2';

const data = {
    labels: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu'],
    datasets: [
        {
            label: 'Ticket',
            data: [12, 19, 3, 5, 2, 20],
            backgroundColor: [
                'rgba(255, 99, 132, 0.8)',
                'rgba(54, 162, 235, 0.8)',
                'rgba(255, 206, 86, 0.8)',
                'rgba(75, 192, 192, 0.8)',
                'rgba(153, 102, 255, 0.8)',
                'rgba(255, 159, 64, 0.8)',
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 0,
        },
    ],
};

var options = {
    scale: {
        ticks: {
            maxTicksLimit: 0
        }
    }
}

function App() {
    const [filter, setFilter] = useState([]);
    const settori = useRecoilValue(settoriState);

    const filterTicket = (id) => {
        if (filter.includes(id)) {
            setFilter(filter.filter(function (value, index, arr) {
                return value != id;
            }));
        }
        else {
            setFilter([...filter, id]);
        }
    }
    return (
        <div className="animate__animated animate__fadeInDown flex flex-wrap paragraph overflow-y-auto p-4 lg:p-10 lg:pt-3 items-stretch items-center rounded-xl  ">
            <h2 className=" text-gray-700 text-3xl mb-6 inline mt-2 lg:mt-0">Ciao <span className="capitalize">{JSON.parse(localStorage.getItem("user"))?.user.username}</span>
                <span className="inline">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 inline ml-2" fill="none" viewBox="0 0 24 24" stroke="#f5c400">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>                </span>
            </h2>
            <br />

            <div className="block w-full">
                <span className="text-lg ">Filtra per Categorie <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline mr-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                </svg></span>
                <button className="inline py-2 mb-4 px-6 text-base mr-4 rounded-md opacity-75 hover:opacity-100" onClick={() => setFilter([])} style={{ border: `4px solid #afafaf` }}>Tutte</button>
                {
                    settori?.map(settore => settore && <button onClick={() => filterTicket(settore.id)} className="inline py-2 mb-4 px-6 text-base mr-4 rounded-md opacity-75 hover:opacity-100" style={!filter.includes(settore.id) ? { border: `4px solid ${settore?.colore}` } : { border: `4px solid ${settore?.colore}`, fontWeight: "bold", textDecoration: "underline" }}>{settore?.nome}</button>
                    )
                }
                <div className="flex gap-8 mb-8 mt-8">
                    <div className="w-1/3 py-10 px-10 bg-gray-100	rounded-3xl	text-gray-900	">
                        <p className="text-lg font-light">Numero ticket aperti (totali)</p>
                        <b>1</b>
                    </div>
                    <div className="w-1/3 py-10 px-10 bg-gray-100	rounded-3xl	text-gray-900	">
                        <p className="text-lg font-light">Numero ticket aperti (oggi)</p>
                        <b>1</b>
                    </div>
                    <div className="w-1/3 py-10 px-10 bg-gray-100	rounded-3xl	text-gray-900	">
                        <p className="text-lg font-light">Numero ticket totali (oggi)</p>
                        <b>1</b>
                    </div>
                </div>
                <div className="shadow bg-gray-50 rounded-3xl flex gap-6	text-gray-900 py-10 px-10 mb-10">
                    <div className="w-1/2">
                        <Bar data={data} options={options} />
                    </div>
                    <div className="w-1/2">
                        <Doughnut data={data} options={{
                            maintainAspectRatio: false
                        }}
                            height={200} width={400}
                        />
                    </div>
                    <div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
