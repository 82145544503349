import React, { useEffect, useState } from "react";
import { executeNetworkRequestWitouthAuth } from './../common/NetworkBuisness.js';
import { Redirect } from "react-router-dom";
import { notify_error, notify_success } from "../common/Toast.js";
export default function Login(props) {
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [logged, setLogged] = useState(false);

    const login = async () => {
        const result = await executeNetworkRequestWitouthAuth("post", "/auth/local", {
            identifier: email,
            password: password,
        })
        //Login avvenuto con successo
        if (result.status === 200) {
            notify_success(`Accesso eseguito per ${result.data.user.username}`)
            localStorage.setItem("user", JSON.stringify(result.data));
            setLogged(true);
        }
        //Login errato per immissione sbagliata delle credenziali
        else if (result.response.data.statusCode === 400 || !result.response) {
            notify_error("Hai inserito email o password sbagliate!");
        }
    }
    useEffect(() => {
        if (localStorage.getItem("user")) {
            //setLogged(true);
        }
    }, [])
    return logged
        ? <Redirect to="/" />
        : (
            <div className="flex align-middle	content-center">
                <div className="flex-1 h-screen align-middle" style={{ backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundImage: `url("https://images.unsplash.com/photo-1521898284481-a5ec348cb555?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=668&q=80")` }}>
                </div>
                <div className="flex flex-1 flex-wrap content-center h-screen align-middle items-center content-center	">
                    <div className="w-full">
                        <div className="px-20 ">
                            <h2 className="text-3xl font-bold mb-8 text-gray-800">Accesso piattaforma Ticket</h2>
                            <label className="block text-xl w-full mb-2 text-gray-600" htmlFor="username-input">Username</label>
                            <input className="px-4 py-3 block rounded-lg text-xl w-2/3 text-gray-600 border border-gray-400" onChange={(e) => { setEmail(e.target.value) }} type="text" id="username-input" placeholder="Es. mario" />
                            <label className="block text-xl mt-4 mb-2 text-gray-600" htmlFor="password-input">Password</label>
                            <input className="px-4 py-3 block rounded-lg text-xl  text-gray-600 w-2/3 border border-gray-400" onChange={(e) => { setPassword(e.target.value) }} type="password" id="password-input" placeholder="password" />
                            <button className="px-8 rounded-lg mt-4 text-xl bg-blue-500  text-white py-2 bg-color-black" onClick={login}>Login</button>
                        </div>
                    </div>
                </div>
            </div>
        )
}
