
import axios from 'axios';

/*
    Esegue una chiamata AJAX al backend senza passare il token.
    type: tipo di chiamata REST (POST,GET,DELETE,PUT)
    path: url di chiamata
    params: oggetto {}, con i valori da passare.
*/
export const executeNetworkRequestWitouthAuth = async (type, path, params) => {
    try {
        const url = process.env.REACT_APP_BACKEND + path;
        return axios({ method: type || 'GET', url, params: type !== 'post' && params, data: params });
    }
    catch (error) {
        return error;
    }
}
/*
    Esegue una chiamata AJAX al backend passando il token.
    type: tipo di chiamata REST (POST,GET,DELETE,PUT)
    path: url di chiamata
    params: oggetto {}, con i valori da passare.
*/
export const executeNetworkRequestWithAuth = async (type, path, params) => {
    try {
        if (!localStorage.getItem("user")) {
            return false;
        }
        const url = process.env.REACT_APP_BACKEND + path;
        const headers = {Authorization: `Bearer ${JSON.parse(localStorage.getItem("user")).jwt}`};
        return axios({method: type || 'GET', url, params: type !== 'post' && params, data: params, headers});
    }
    catch (error) {
        return error;
    }
}