import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const notify_success = (messaggio) => toast.success(messaggio, {
    position: "bottom-right",
    autoClose: 1500,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
})
export const notify_error = (messaggio) => toast.error(messaggio, {
    position: "bottom-right",
    autoClose: 2500,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
});

export const notify_info = () => toast("Wow so easy!");

export const notify_loading = (messaggio) => toast.loading(messaggio, { autoClose: 2500 });

export const notify_loading_finish = (id, messaggio) => toast.update(id, {
    render: messaggio, type: "info", isLoading: false, autoClose: 1500, hideProgressBar: true,    position: "bottom-right",

});