import { useForm, Controller } from "react-hook-form";
import Select, { components } from 'react-select'
import { useEffect, useState } from "react";
import { executeNetworkRequestWithAuth } from './../common/NetworkBuisness.js';

import Modal from 'react-modal';
import makeAnimated from 'react-select/animated';
import {
    useRecoilValue,
} from 'recoil';

import { usersState, settoriState, notificationState } from "../common/Store.js";
import { useTask, useUser, useNotification } from "../common/HookStore.js";
import { notify_success } from "../common/Toast";
import {
    Link, Redirect
} from "react-router-dom";
import { notify_error } from "../common/Toast";


export default () => {
    const task = useTask();
    const usersHook = useUser();
    const notificationHook = useNotification();
    const notification = useRecoilValue(notificationState);
    const [notificationSave, setNotificationSave] = useState([]);;

    const users = useRecoilValue(usersState);
    const settori = useRecoilValue(settoriState);

    const [modalIsOpen, setIsOpen] = useState(false);
    const [sideIsOpen, setSideIsOpen] = useState(true);
    const [loginSuccess, setLoginSuccess] = useState(false);
    const [loading, setLoading] = useState(true);

    const animatedComponents = makeAnimated();
    const { register, handleSubmit, control } = useForm();
    
    const onSubmit = async (data) => {
        if (await createTask(data)) {
            setIsOpen(false);
            notify_success("Task inserito correttamente");
        }
        else {
            notify_error("Errore nell'inserimento del ticket")
        }

    };
    //concat two array
    const concatArray = (array1, array2) => {
        return array1.concat(array2);
    }
    const createTask = async (data) => {
        console.log(data);

        const task_new = await executeNetworkRequestWithAuth("post", "/tickets", {
            nome: data.nome,
            descrizione: data.descrizione,
            data_creazione: Date.now(),
            data_modifica: Date.now(),
            priorita: data.priorita,
            settore: data.settore == "null" ? undefined : data.settore,
            stato: "aperto",
            utente_creato: JSON.parse(localStorage.getItem("user")).user.id,
            associato_as: data.associato_a?.length > 0 ? data.associato_a.map(associato => associato.data) : settori.find(settore => parseInt(settore.id) === parseInt(data.settore)).capo_settori.map(capo => capo.id).concat(settori.find(settore => parseInt(settore.id) === parseInt(data.settore)).partecipanti.map(capo => capo.id)),
        });
        if (task_new.status === 200) {
            console.log(data.associato_a);
            if (data.associato_a?.length > 0) {
                data.associato_a.map(async associato => {
                    await executeNetworkRequestWithAuth("post", "/notificas", {
                        nome: `Nuovo ticket da ${JSON.parse(localStorage.getItem('user')).user.username} - ${data.nome.substring(0, 25)} `,
                        associato_as: associato.data,
                        ticket: task_new.data.id
                    });
                });
            }
            else if (settori.find(settore => parseInt(settore.id) === parseInt(data.settore)).capo_settori[0]?.id) {
                await executeNetworkRequestWithAuth("post", "/notificas", {
                    nome: `Nuovo ticket da ${JSON.parse(localStorage.getItem("user")).user.username} - ${data.nome.substring(0, 25)} `,
                    associato_as: settori.find(settore => parseInt(settore.id) === parseInt(data.settore)).capo_settori[0]?.id,
                    ticket: task_new.data.id
                });
            }
            task.getTask();
            return true;
        }
        return false;
    }
    const getUsers = () => {
        usersHook.getUsers();
    }
    const getNotificationtest = () => {
        notificationHook.getNotification();
    }
    useEffect(() => {
        if (notificationSave.length == 0) {
            setNotificationSave([...notification]);
            return;
        }
        if (!notification.filter(notify => notify.letta == false).length || notification.filter(notify => notify.letta == false).length == 0) {
            return;
        }
        console.log(notification.filter(notify => notify.letta == false).length);
        if (notificationSave.filter(notify => notify.letta == false).length > notification.filter(notify => notify.letta == false).length) {
            return;
        }
        setNotificationSave([...notification]);
        const audio = new Audio(process.env.PUBLIC_URL + '/notification.wav');
        audio.play();
        document.title = `Hai (${notification.filter(notify => notify.letta == false).length}) notifiche non lette`
    }, [notification.filter(notify => notify.letta == false).length])


    const getNotificationPeriodic = (user) => {
        setInterval(function test() {
            if (user) {
                getNotificationtest();
            }
        }, 10000);

    }

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));
        if (!user) {
            setLoginSuccess(true);
        }
        else {
            getUsers();
            task.getTask();
            notificationHook.getNotification();
            getNotificationPeriodic(user);
        }
        setLoading(false);
    }, [])

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            minWidth: "65%",
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            border: "0px",
            boxShadow: " 0px 0px 30px -17px #000000",
            borderRadius: "15px",
        },
    };

    return loading ? <div>Loading</div> : !loginSuccess ?
        <div className="flex" style={sideIsOpen ? { minWidth: "250px" } : {}}>
            <div className="pt-6 cursor-pointer" style={{ position: "absolute", top: "10", zIndex: "1" }}>
                <a onClick={() => setSideIsOpen(!sideIsOpen)}><svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h7" />
                </svg></a>
            </div>
            {sideIsOpen && <ul className="pt-8 pr-10 w-full animate__animated animate__fadeInLeft animate__faster" >
                <li className="mb-16 pl- mt-10"><img alt="logo-pages" style={{ width: "110px" }} src="https://infermieripisa.it/wp-content/uploads/2021/06/logo.png" /></li>
                <Link to="/"><li className="mb-5   text-lg text-gray-600 hover:text-blue-500 cursor-pointer	">Home</li></Link>
                <Link to="/ticket"><li className="mb-6   text-lg	text-gray-600 hover:text-blue-500 cursor-pointer">Elenco richieste</li></Link>
                {JSON.parse(localStorage.getItem("user")).user.role.name != "opertore" && <Link to="/grid"><li className="mb-6  text-gray-600 text-lg hover:text-blue-500 cursor-pointer">Bacheca</li></Link>}
                {JSON.parse(localStorage.getItem("user")).user.role.name != "opertore" && <Link to="/dashboard"><li className="mb-6  text-gray-600 text-lg hover:text-blue-500 cursor-pointer">Dashboard</li></Link>}
                <li className="bg-blue-500	text-white p-2 pl-5 shadow-md	 rounded-lg	text-lg	cursor-pointer" onClick={() => setIsOpen(true)}>Inoltra chiamata</li>
                <li className="bg-red-400	mt-5 text-white p-2 pl-5 shadow-md	 rounded-lg	text-lg	cursor-pointer" onClick={() => {
                    localStorage.removeItem("user");
                    setLoginSuccess(true);
                }}>Esci</li>
            </ul>}
            <Modal
                isOpen={modalIsOpen}
                contentLabel="Inoltra chiamata"
                style={customStyles}
            >
                <div className="p-5 pt-1 ">
                    <div className="text-right mb-5">
                        <h2 className="text-xl font-bold mb-6 inline float-left">Aggiungi nuova Richiesta</h2>
                        <button className="inline" onClick={() => setIsOpen(false)}><svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 " fill="none" viewBox="0 0 24 24" stroke="black">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                        </button>
                    </div>
                    <div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <label className="w-full block mb-2 text-lg">Titolo richiesta</label>
                            <input  {...register("nome")} className="w-full border b-1 rounded-lg p-3 block mb-4" type="text"></input>
                            <div className="flex gap-8">
                                <div className="w-1/2">
                                    <label className="block mb-2 text-lg">Priorità</label>
                                    <select {...register("priorita")} className="w-full rounded-lg mb-4 border b-1 p-4 bg-white">
                                        <option value="1">Alta</option>
                                        <option value="2">Media</option>
                                        <option value="3">Bassa</option>
                                    </select>

                                </div>
                                <div className="w-1/2">
                                    <label className="text-lg">Categoria</label>

                                    <select className="p-4 rounded-lg block w-full bg-white border b-1 mt-2" {...register("settore", { required: true })}>
                                        <option value={"null"}>{"Nessun settore"}</option>
                                        {settori?.map(settore => settore && <option value={settore?.id}>{settore?.nome}</option>)}
                                    </select>
                                </div>
                            </div>
                            <label className="text-lg mb-4">Assegnatario</label>
                            <Controller
                                name="associato_a"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        className="custom-select"
                                        {...field}
                                        name="associato_a"
                                        options={users?.map(user => {
                                            return {
                                                value: user.username, data: user.id, label: <div ><img className="w-8 h-8 rounded-full	inline mr-2" src={process.env.REACT_APP_BACKEND + user.immagine_profilo?.url} />
                                                    <span className="capitalize">{user.username}</span>
                                                </div>
                                            }
                                        })}
                                        isMulti
                                        isSearchable={true}
                                        autoComplete={true}
                                        components={animatedComponents}
                                    />
                                )}
                                className="mb-2 mt-2  custom-select"
                            />
                            <label className="text-lg mb-2 block mt-4">Descrizione richiesta</label>
                            <textarea {...register("descrizione")} rows="5" className="rounded-lg p-3 block w-full border b-1"></textarea>
                            <button type="submit" className="shadow-md mt-8 text-white bg-blue-500 px-6 py-2 text-xl rounded-lg">Aggiungi</button>
                        </form>
                    </div>
                </div>
            </Modal>
        </div> : <Redirect to="/login" />
}
