import { useEffect, useState } from "react";
import { executeNetworkRequestWithAuth } from './../common/NetworkBuisness.js';
import { notify_error, notify_success } from "../common/Toast.js";
import ReactMarkdown from "react-markdown";
import mime from "mime";

export default function Home(props) {
    const [comunicazioni, setComunicazioni] = useState([]);
    const [comunicazioniFiltered, setComunicazioniFiltered] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        setComunicazioniFiltered(comunicazioni.filter(comunicazione => { return comunicazione.titolo?.replace(' ', '').toUpperCase().includes(searchQuery?.replace(' ', '').toUpperCase()) || comunicazione.descrizione_lunga?.replace(' ', '').toUpperCase().includes(searchQuery?.replace(' ', '').toUpperCase()) }));
    }, [searchQuery]);

    useEffect(async () => {
        const result = await executeNetworkRequestWithAuth("get", `/comunicazionis`, []);
        if (result.status === 200) {
            setComunicazioni(result.data);
        }
        else {
            notify_error("Errore nel caricamento delle comunicazioni")
        }
    }, [])

    return <div className="animate__animated animate__fadeInDown flex flex-wrap paragraph overflow-y-auto p-4 lg:p-10 lg:pt-3 items-stretch items-center rounded-xl  w-full">
        <h2 className=" text-gray-700 text-3xl mb-6 inline mt-2 lg:mt-0">Cerca risposte
            <span className="inline">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 inline ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z" />
                </svg> </span>       </h2>
        <br />
        <div className="w-full mr-2 ml-2">
            <div className="bg-white shadow p-2 flex mt-2 rounded-3xl">
                <span className="w-auto flex justify-end items-center text-gray-500 p-2 ">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                    </svg>
                </span>
                <input onChange={(e) => { setSearchQuery(e.target.value) }} className="w-full rounded p-2" type="text" placeholder="Prova 'Orari apertura ufficio'" />
                <button className="bg-red-400 hover:bg-red-300 rounded-3xl text-white p-2 pl-4 pr-4">
                    <p className="font-semibold text-xs ">Cerca</p>
                </button>
            </div>
        </div>
        <div className="block w-full bg-gray-100 rounded-3xl shadow" style={{ zIndex: "-1", marginTop: "-35px", minHeight: "450px", width: "100%" }}>
            <div className="flex gap-8 mb-8 mt-8 p-6">
                {comunicazioniFiltered.length > 0 ? comunicazioniFiltered.map(comunicazione => <div className="w-full  xl:w-1/3 py-8 px-8 bg-white shadow	rounded-3xl	text-gray-900	">
                    <p className="text-lg font-bold mb-2">{comunicazione.titolo}</p>
                    {
                        {
                            'pdf': <a target="_blank" href={`http${process.env.REACT_APP_BACKEND}{comunicazione.file_associato?.url}`}><img style={{ width: "auto", height: "55px", margin: "0 auto" }} src={`http://localhost:1337/uploads/png_transparent_pdf_computer_icons_document_mall_miscellaneous_text_logo_removebg_preview_f768de2e59.png`} />
                                {comunicazione.file_associato?.name}
                            </a>,
                            'xlsx': <a target="_blank" href={`${process.env.REACT_APP_BACKEND}${comunicazione.file_associato?.url}`}><img style={{ width: "auto", height: "50px", margin: "0 auto" }} src={`http://localhost:1337/uploads/excel_2_190764_removebg_preview_5944406dab.png`} />
                                {comunicazione.file_associato?.name}
                            </a>,

                            'doc': <a target="_blank" href={`${process.env.REACT_APP_BACKEND}${comunicazione.file_associato?.url}`}><img style={{ width: "auto", height: "55px", margin: "0 auto" }} src={`http://localhost:1337/uploads/doc_file_1934509_1634559_removebg_preview_1ac8ad94ef.png`} />
                                {comunicazione.file_associato?.name}
                            </a>,
                            'png': <a target="_blank" href={`${process.env.REACT_APP_BACKEND}${comunicazione.file_associato?.url}`}><img style={{ width: "auto", height: "55px", margin: "0 auto" }} src={`http://localhost:1337${comunicazione.file_associato?.url}`} />
                                {comunicazione.file_associato?.name}
                            </a>,
                            'jpg': <a target="_blank" href={`${process.env.REACT_APP_BACKEND}${comunicazione.file_associato?.url}`}><img style={{ width: "auto", height: "55px", margin: "0 auto" }} src={`http://localhost:1337${comunicazione.file_associato?.url}`} />
                                {comunicazione.file_associato?.name}
                            </a>,
                        }[mime.getExtension(comunicazione.file_associato?.mime)]
                    }
                    <ReactMarkdown children={comunicazione.descrizione_lunga} />
                </div>
                ) : comunicazioni.map(comunicazione => <div className="w-full  xl:w-1/3 py-8 px-8 bg-white shadow	rounded-3xl	text-gray-900	">
                    <p className="text-lg font-bold mb-2">{comunicazione.titolo}</p>
                    <div className="text-center mb-2 mt-2">
                        {
                            {
                                'pdf': <a target="_blank" href={`${process.env.REACT_APP_BACKEND}${comunicazione.file_associato?.url}`}><img style={{ width: "auto", height: "55px", margin: "0 auto" }} src={`http://localhost:1337/uploads/png_transparent_pdf_computer_icons_document_mall_miscellaneous_text_logo_removebg_preview_f768de2e59.png`} />
                                    {comunicazione.file_associato?.name}
                                </a>,
                                'xlsx': <a target="_blank" href={`${process.env.REACT_APP_BACKEND}${comunicazione.file_associato?.url}`}><img style={{ width: "auto", height: "50px", margin: "0 auto" }} src={`http://localhost:1337/uploads/excel_2_190764_removebg_preview_5944406dab.png`} />
                                    {comunicazione.file_associato?.name}
                                </a>,

                                'doc': <a target="_blank" href={`${process.env.REACT_APP_BACKEND}${comunicazione.file_associato?.url}`}><img style={{ width: "auto", height: "55px", margin: "0 auto" }} src={`http://localhost:1337/uploads/doc_file_1934509_1634559_removebg_preview_1ac8ad94ef.png`} />
                                    {comunicazione.file_associato?.name}
                                </a>,
                                'png': <a target="_blank" href={`${process.env.REACT_APP_BACKEND}${comunicazione.file_associato?.url}`}><img style={{ width: "auto", height: "55px", margin: "0 auto" }} src={`http://localhost:1337${comunicazione.file_associato?.url}`} />
                                    {comunicazione.file_associato?.name}
                                </a>,
                                'jpg': <a target="_blank" href={`${process.env.REACT_APP_BACKEND}${comunicazione.file_associato?.url}`}><img style={{ width: "auto", height: "55px", margin: "0 auto" }} src={`http://localhost:1337${comunicazione.file_associato?.url}`} />
                                    {comunicazione.file_associato?.name}
                                </a>,
                            }[mime.getExtension(comunicazione.file_associato?.mime)]
                        }
                    </div>
                    <ReactMarkdown children={comunicazione.descrizione_lunga} />
                </div>
                )
                }
            </div>
        </div>
    </div>
}