import { useEffect, useState } from "react";
import moment from "moment";
import Modal from 'react-modal';
import { useRecoilValue } from 'recoil';
import { notify_success, notify_loading, notify_loading_finish, notify_error } from "../common/Toast";
import { executeNetworkRequestWithAuth } from './../common/NetworkBuisness.js';
import makeAnimated from 'react-select/animated';
import Select from 'react-select'
import { Collapse } from 'react-collapse';

import { tickets, settoriState, usersState } from "../common/Store.js";
import { useTask } from "../common/HookStore.js";

function Grid() {
    const task = useRecoilValue(tickets);
    const settori = useRecoilValue(settoriState);
    const [filter, setFilter] = useState([]);
    const [filterStato, setFilterStato] = useState([]);
    const [filterDate, setFilterDate] = useState([]);
    const [collapseOpen, setCollapseOpen] = useState(false);

    const [associatoAs, setAssociatoAsEdit] = useState([]);

    const users = useRecoilValue(usersState);

    const animatedComponents = makeAnimated();

    const [taskSelected, setTasksSelected] = useState({});
    const [textMessage, setTextMessage] = useState("");
    const [editPriorita, setEditPriorita] = useState("");
    const [editAssociato, setEditAssociato] = useState(false);

    const [windowOpen, setWindowOpen] = useState(false);

    const [messaggiosSelected, setMessaggiosSelected] = useState([]);
    const [modalOpenMessage, setModalOpenMessage] = useState(false);
    const [prioritaSelected, setPrioritaSelected] = useState(1);

    const Task = useTask();

    const getMessagesFromTicket = async () => {
        const id_notify = notify_loading("Caricamento messaggi");
        const messaggios = await executeNetworkRequestWithAuth("get", `/messaggios?ticket.id=${taskSelected.id}`, []);
        setMessaggiosSelected(messaggios.data);
        notify_loading_finish(id_notify, "Caricamento messaggi riuscito");
    }

    const updateTicket = async (nome_attributo, valore) => {
        /*
        ROLE: 
            1 -> ADMIN
            2 -> Capo Area
            3 -> Operatore
        */
        const check_change = messaggiosSelected.map(messaggio => {
            if (messaggio.users_permissions_user.role == 1) {
                return true;
            }
            const is_commented_associato = taskSelected.associato_as.map(associato =>
                associato.id == messaggio.users_permissions_user.id
            )
            if (is_commented_associato) {
                return true;
            }
            if (messaggio.users_permissions_user.id == settori.find(settore => settore.id === taskSelected.settore.id).capo_settori[0].id) {
                return true;
            }
            return false;
        });
        if (!check_change.includes(true)) {
            notify_error("Il ticket non è stato commentato da un Amministratore, dal referente o da una delle persone associate");
            return;
        }


        if (nome_attributo == "priorita" && !editPriorita) {
            return;
        }
        if (nome_attributo == "associato_as" && !editAssociato) {
            return;
        }
        const id_notify = notify_loading("Modifica ticket in corso");
        const messaggios = await executeNetworkRequestWithAuth("put", `/tickets/${taskSelected.id}`, {
            [nome_attributo]: nome_attributo == "priorita" ? parseInt(valore) : valore
        });
        setWindowOpen(false);
        if (messaggios.status === 200) {
            setTimeout(() => {
                setTasksSelected({});
                setModalOpenMessage(false);
                notify_loading_finish(id_notify, "Ticket modifica con successo");
                Task.getTask();
            }, 1200);
        }
        else {
            notify_error("Errore nella modifica del ticket")
        }
    }

    const insertMessage = async () => {
        if (textMessage.length <= 0) {
            notify_error("Non hai inserito un contenuto nel messaggio")
            return;
        }
        const messaggios = await executeNetworkRequestWithAuth("post", `/messaggios`, {
            Testo: textMessage,
            users_permissions_user: JSON.parse(localStorage.getItem("user")).user.id,
            ticket: taskSelected.id
        });
        if (messaggios.data.id) {
            if (taskSelected.associato_as?.length > 0) {
                taskSelected.associato_as.map(async associato => {
                    await executeNetworkRequestWithAuth("post", "/notificas", {
                        nome: `Nuovo commento da ${JSON.parse(localStorage.getItem('user')).user.username} nel ticket ${taskSelected.nome} `,
                        associato_as: associato.id,
                        ticket: taskSelected.id
                    });
                });
            }
            notify_success("Messaggio inserito correttamente");
            setTextMessage("");
            getMessagesFromTicket();
        }
        else {
            notify_error("Problema nell'inserimento del messaggio");
        }
    }

    const goToBottom = () => {
        var objDiv = document.getElementById("message-box");
        objDiv.scrollTop = objDiv.scrollHeight;
    }

    useEffect(() => {
        if (taskSelected.id)
            getMessagesFromTicket();
    }, [taskSelected]);

    const filterTicketSettore = (id) => {
        if (filter.includes(id)) {
            setFilter(filter.filter(function (value, index, arr) {
                return value != id;
            }));
        }
        else {
            setFilter([...filter, id]);
        }
    }
    
    const filterTicketStato = (name) => {
        if (filterStato.includes(name)) {
            setFilterStato(filterStato.filter((value, index, arr) => {
                return value != name;
            }));
        }
        else {
            setFilterStato([...filterStato, name]);
        }
    }

    function isSameDay(d1, d2) {
        return d1.getFullYear() === d2.getFullYear() &&
            d1.getDate() === d2.getDate() &&
            d1.getMonth() === d2.getMonth();
    }

    const filterTicketDate = (name) => {
        switch (name) {
            case "oggi":
                task.map((value) => {
                    let date1 = new Date(value.data_creazione);
                    let date_now = new Date();
                    date_now.setHours(0, 0, 0, 0);
                    date1.setHours(0, 0, 0, 0)
                    if (isSameDay(date1, date_now)) {
                        setFilterDate([...filterDate, value.id]);
                    }
                })
                break;
            case "ieri":
                task.map((value) => {
                    let date1 = new Date(value.data_creazione);
                    let date_now = new Date();
                    date_now.setDate(date_now.getDate() - 1)
                    date_now.setHours(0, 0, 0, 0);
                    date1.setHours(0, 0, 0, 0)
                    if (isSameDay(date1, date_now)) {
                        setFilterDate([...filterDate, value.id]);
                    }
                })
                //setFilterDate([null])
                break;
            case "scorsa_settimana":
                task.map((value) => {
                    let date1 = new Date(value.data_creazione);
                    let date_now = new Date();
                    date_now.setDate(date_now.getDate() - 1)
                    date_now.setHours(0, 0, 0, 0);
                    date1.setHours(0, 0, 0, 0)
                    if (isSameDay(date1, date_now)) {
                        setFilterDate([...filterDate, value.id]);
                    }
                })
                setFilterDate([null])
                break;

            case "Tutte":
                setFilterDate([]);
                break;
            default:
                break;
        }
        if (filterDate.includes(name)) {
        }
        else {
        }
    }

    const customStylesMessage = {
        content: {
            top: '50%',
            left: '50%',
            minWidth: "70%",
            minHeight: "80vh",
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            opacity: "1",
            transform: 'translate(-50%, -50%)',
            border: "0px",
            boxShadow: " 0px 0px 30px -17px #000000",
            borderRadius: "15px",
        },
    };

    return (
        <div className="animate__animated animate__fadeInDown flex flex-wrap paragraph overflow-y-auto p-4 lg:p-10 lg:pt-3 items-stretch items-center rounded-xl  ">
            <div onClick={() => { setCollapseOpen(!collapseOpen) }} className="border-b-2 border-gray-600 mb-6 w-full underline cursor-pointer"><h2 className="inline" >Filtri</h2>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 inline ml-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z" />
                </svg>
            </div>
            <Collapse isOpened={collapseOpen}>
                <div className="block w-full">
                    <span className="text-xs ">Categorie <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline mr-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                    </svg></span>
                    <button className="inline py-2 mb-4 px-6 text-xs mr-4 rounded-md opacity-75 hover:opacity-100" onClick={() => setFilter([])} style={{ border: `3px solid #afafaf` }}>Tutte</button>
                    {
                        settori?.map(settore => settore && <button onClick={() => filterTicketSettore(settore.id)} className="inline py-2 mb-4 px-6 text-xs mr-4 rounded-md opacity-75 hover:opacity-100" style={!filter.includes(settore.id) ? { border: `2px solid ${settore?.colore}` } : { border: `4px solid ${settore?.colore}`, fontWeight: "bold", textDecoration: "underline" }}>{settore?.nome}</button>
                        )
                    }
                </div>
                <div className="block w-full">
                    <span className="text-xs ">Stato <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline mr-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                    </svg></span>
                    <select className="bg-white px-2 border-2 text-sm" onChange={(e) => e.target.value !== "*" ? filterTicketStato(e.target.value) : setFilterStato([])}>
                        <option value="*" className="inline py-2 mb-4 px-6 text-xs mr-4 rounded-md opacity-75 hover:opacity-100" style={{ border: `3px solid #afafaf` }}>Tutte</option>
                        <option value="aperto" className={`inline py-2 mb-4 px-6 text-xs mr-4 rounded-md opacity-75 hover:opacity-100  ${!filterStato.includes('aperto') ? "border-2" : "border-4 font-bold"}  border-red-500`} >Aperto</option>
                        <option value="in_valutazione" className={`inline py-2 mb-4 px-6 text-xs mr-4 rounded-md opacity-75 hover:opacity-100  ${!filterStato.includes('in_valutazione') ? "border-2" : "border-4 font-bold"} border-yellow-500 `}>In valutazione</option>
                        <option value="in_corso" className={`inline py-2 mb-4 px-6 text-xs mr-4 rounded-md opacity-75 hover:opacity-100  ${!filterStato.includes('in_corso') ? "border-2" : "border-4 font-bold"} border-yellow-800`}>In Corso</option>
                        <option value="chiuso" className={`inline py-2 mb-4 px-6 text-xs mr-4 rounded-md opacity-75 hover:opacity-100  ${!filterStato.includes('chiuso') ? "border-2" : "border-4 font-bold"} border-green-800`}>Chiuso</option>
                    </select>
                    <span className="text-xs ml-8 ">Data
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline mr-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                        </svg>
                    </span>
                    <select onChange={(e) => filterTicketDate(e.target.value)} className="bg-white px-2 border-2 text-sm">
                        <option className="inline py-2 mb-4 px-6 text-xs mr-4 rounded-md opacity-75 hover:opacity-100" onClick={() => setFilterStato([])} style={{ border: `3px solid #afafaf` }}>Tutte</option>
                        <option value="oggi" style={{ border: `3px solid #afafaf` }} className={`inline py-2 mb-4 px-6 text-xs mr-4 rounded-md opacity-75 hover:opacity-100  ${!filterStato.includes('aperto') ? "border-2" : "border-4 font-bold"} `} >Oggi</option>
                        <option value="ieri" style={{ border: `3px solid #afafaf` }} className={`inline py-2 mb-4 px-6 text-xs mr-4 rounded-md opacity-75 hover:opacity-100  ${!filterStato.includes('in_valutazione') ? "border-2" : "border-4 font-bold"} `}>Ieri</option>
                        <option value="scorsa_settimana" style={{ border: `3px solid #afafaf` }} className={`inline py-2 mb-4 px-6 text-xs mr-4 rounded-md opacity-75 hover:opacity-100  ${!filterStato.includes('in_corso') ? "border-2" : "border-4 font-bold"}`}>Questa settimana</option>

                    </select>
                </div>
            </Collapse>

            <div className="grid grid-cols-12 pt-4 pb-4 w-full gap-4">
                <div className="text-gray-700 col-span-6  md:col-span-6 xl:col-span-3  rounded-xl p-6 bg-white	">
                    <h2 className="font-bold text-xl">Aperto
                        <span className="font-normal">({task?.map(task =>
                            task.stato == "aperto" && (((task.settore && filter.includes(task?.settore?.id)) || filter.length == 0)) &&
                            (filterStato.includes(task.stato) || filterStato.length === 0) && (filterDate.includes(task.id) || filterDate.length === 0)).filter(Boolean).length})
                        </span>
                    </h2>
                    {task?.map(task =>
                        task.stato == "aperto" && (((task.settore && filter.includes(task?.settore?.id)) || filter.length == 0)) &&
                        (filterStato.includes(task.stato) || filterStato.length === 0) && (filterDate.includes(task.id) || filterDate.length === 0) &&
                        <div key={task.id} className="text-gray-700 col-span-12  shadow-md rounded-xl p-4 mt-4 bg-white	" style={{ minHeight: "250px", borderBottom: task.settore ? `6px solid ${task.settore.colore}` : "" }}>
                            <div className="grid grid-cols-12 pt-4 pb-4 w-full">
                                <div className="col-span-12 lg:col-span-6">
                                    <span className="font-bold text-lg">{task.nome}</span>
                                </div>
                                <div className="col-span-12 lg:col-span-6 text-right">
                                    <span className="text-sm font-bold">{
                                        {
                                            "aperto": <span className="whitespace-nowrap	 bg-red-500 text-white text-xs px-4 py-2 rounded-lg">Aperto</span>,
                                            "in_valutazione": <span className="whitespace-nowrap	 bg-yellow-500 text-xs text-white px-4 py-2 rounded-lg">In valutazione</span>,
                                            "in_corso": <span className="whitespace-nowrap	bg-blue-800 text-xs text-white px-4 py-2 rounded-lg">In corso</span>,
                                            "chiuso": <span className="whitespace-nowrap	 bg-green-500 text-xs text-white px-4 py-2 rounded-lg">Chiuso</span>
                                        }[task.stato]
                                    }</span>
                                </div>
                            </div>

                            <p className="font-300 text-md" style={{ minHeight: "15px" }}>{task.descrizione && task.descrizione} {task.descrizione?.length > 60 && "..."} </p>
                            <p className="font-300 text-sm mt-4">Aperto da: <b className="capitalize">{task.utente_creato?.username}</b></p>
                            <p className="font-300 text-sm ">Associato a: <b>
                                {task.associato_as.length > 0 ?
                                    task.associato_as.map(associato => <span className="capitalize mr-2">{associato.username}</span>) :
                                    <span className="capitalize mr-2"> {task.associato_as.username}</span>
                                }</b></p>

                            <p className="font-300 text-sm ">Priorità: <span className="font-bold">{
                                {
                                    1: <span className="text-red-600">Alta</span>,
                                    2: <span className="text-yellow-500	">Medio</span>,
                                    3: <span className="text-blue-500">Basso</span>
                                }[task.priorita]
                            }</span></p>
                            <p className="font-300 text-sm ">Data creazione: <span className="font-bold">{moment(task.data_creazione).format('DD/MM/YYYY HH:mm')}</span></p>
                            <p className="font-300 text-sm ">Data modifica: <span className="font-bold">{moment(task.data_modifica).format('DD/MM/YYYY HH:mm')}</span></p>
                            <br />
                            <p onClick={() => {
                                setTasksSelected(task); setModalOpenMessage(true); setTimeout(() => {
                                    goToBottom();
                                }, 300);
                            }} className="cursor-pointer text-gray-700 text-sm pt-8 mt-10 inline">
                                <span className="inline"><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z" />
                                </svg>
                                    <span className="ml-2"></span>
                                </span>
                            </p>
                        </div>

                    )}
                </div>
                <div className="text-gray-700 col-span-6  md:col-span-6 xl:col-span-3  rounded-xl p-6 bg-white	">
                    <h2 className="font-bold text-xl">
                        In valutazione
                        <span className="font-normal">({task?.map(task =>
                            task.stato == "in_valutazione" && (((task.settore && filter.includes(task?.settore?.id)) || filter.length == 0)) &&
                            (filterStato.includes(task.stato) || filterStato.length === 0) && (filterDate.includes(task.id) || filterDate.length === 0)).filter(Boolean).length})</span>
                    </h2>
                    {task?.map(task =>
                        (((task.settore && filter.includes(task?.settore?.id)) || filter.length == 0)) &&
                        (filterStato.includes(task.stato) || filterStato.length === 0) && (filterDate.includes(task.id) || filterDate.length === 0) && task.stato == "in_valutazione" &&
                        <div key={task.id} className="text-gray-700 col-span-12  shadow-md rounded-xl p-4 mt-4 bg-white	" style={{ minHeight: "250px", borderBottom: task.settore ? `6px solid ${task.settore.colore}` : "" }}>
                            <div className="grid grid-cols-12 pt-4 pb-4 w-full">
                                <div className="col-span-12 lg:col-span-6">
                                    <span className="font-bold text-lg">{task.nome}</span>
                                </div>
                                <div className="col-span-12 lg:col-span-6 text-right">
                                    <span className="text-sm font-bold">{
                                        {
                                            "aperto": <span className="whitespace-nowrap	 bg-red-500 text-white text-xs px-4 py-2 rounded-lg">Aperto</span>,
                                            "in_valutazione": <span className="whitespace-nowrap	 bg-yellow-500 text-xs text-white px-4 py-2 rounded-lg">In valutazione</span>,
                                            "in_corso": <span className="whitespace-nowrap	bg-blue-800 text-xs text-white px-4 py-2 rounded-lg">In corso</span>,
                                            "chiuso": <span className="whitespace-nowrap	 bg-green-500 text-xs text-white px-4 py-2 rounded-lg">Chiuso</span>
                                        }[task.stato]
                                    }</span>
                                </div>
                            </div>

                            <p className="font-300 text-md" style={{ minHeight: "15px" }}>{task.descrizione && task.descrizione}</p>
                            <p className="font-300 text-sm mt-4">Aperto da: <b className="capitalize">{task.utente_creato?.username}</b></p>
                            <p className="font-300 text-sm ">Associato a: <b>
                                {task.associato_as.length > 0 ?
                                    task.associato_as.map(associato => <span className="capitalize mr-2">{associato.username}</span>) :
                                    <span className="capitalize mr-2"> {task.associato_as.username}</span>
                                }</b></p>

                            <p className="font-300 text-sm ">Priorità: <span className="font-bold">{
                                {
                                    1: <span className="text-red-600">Alta</span>,
                                    2: <span className="text-yellow-500	">Medio</span>,
                                    3: <span className="text-blue-500">Basso</span>
                                }[task.priorita]
                            }</span></p>
                            <p className="font-300 text-sm ">Data creazione: <span className="font-bold">{moment(task.data_creazione).format('DD/MM/YYYY HH:mm')}</span></p>
                            <p className="font-300 text-sm ">Data modifica: <span className="font-bold">{moment(task.data_modifica).format('DD/MM/YYYY HH:mm')}</span></p>
                            <br />
                            <p onClick={() => {
                                setTasksSelected(task); setModalOpenMessage(true); setTimeout(() => {
                                    goToBottom();
                                }, 300);
                            }} className="cursor-pointer text-gray-700 text-sm pt-8 mt-10 inline">
                                <span className="inline"><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z" />
                                </svg>
                                    <span className="ml-2"></span>
                                </span>
                            </p>
                        </div>

                    )}
                </div>

                <div className="text-gray-700 col-span-6  md:col-span-6 xl:col-span-3  rounded-xl p-6 bg-white	">
                    <h2 className="font-bold text-xl inline">
                        In corso
                        <span className="font-normal">({task?.map(task =>
                            task.stato == "in_corso" && (((task.settore && filter.includes(task?.settore?.id)) || filter.length == 0)) &&
                            (filterStato.includes(task.stato) || filterStato.length === 0) && (filterDate.includes(task.id) || filterDate.length === 0)).filter(Boolean).length})
                        </span>
                    </h2>
                    {task?.map(task =>
                        (((task.settore && filter.includes(task?.settore?.id)) || filter.length == 0)) &&
                        (filterStato.includes(task.stato) || filterStato.length === 0) && (filterDate.includes(task.id) || filterDate.length === 0) && task.stato == "in_corso" &&
                        <div key={task.id} className="text-gray-700 col-span-12  shadow-md rounded-xl p-4 mt-4 bg-white	" style={{ minHeight: "250px", borderBottom: task.settore ? `6px solid ${task.settore.colore}` : "" }}>
                            <div className="grid grid-cols-12 pt-4 pb-4 w-full">
                                <div className="col-span-12 lg:col-span-6">
                                    <span className="font-bold text-lg">{task.nome}</span>
                                </div>
                                <div className="col-span-12 lg:col-span-6 text-right">
                                    <span className="text-sm font-bold">{
                                        {
                                            "aperto": <span className="whitespace-nowrap	 bg-red-500 text-white text-xs px-4 py-2 rounded-lg">Aperto</span>,
                                            "in_valutazione": <span className="whitespace-nowrap	 bg-yellow-500 text-xs text-white px-4 py-2 rounded-lg">In valutazione</span>,
                                            "in_corso": <span className="whitespace-nowrap	bg-blue-800 text-xs text-white px-4 py-2 rounded-lg">In corso</span>,
                                            "chiuso": <span className="whitespace-nowrap	 bg-green-500 text-xs text-white px-4 py-2 rounded-lg">Chiuso</span>
                                        }[task.stato]
                                    }</span>
                                </div>
                            </div>

                            <p className="font-300 text-md" style={{ minHeight: "15px" }}>{task.descrizione && task.descrizione} </p>
                            <p className="font-300 text-sm mt-4">Aperto da: <b className="capitalize">{task.utente_creato?.username}</b></p>
                            <p className="font-300 text-sm ">Associato a: <b>
                                {task.associato_as.length > 0 ?
                                    task.associato_as.map(associato => <span className="capitalize mr-2">{associato.username}</span>) :
                                    <span className="capitalize mr-2"> {task.associato_as.username}</span>
                                }</b></p>

                            <p className="font-300 text-sm ">Priorità: <span className="font-bold">{
                                {
                                    1: <span className="text-red-600">Alta</span>,
                                    2: <span className="text-yellow-500	">Medio</span>,
                                    3: <span className="text-blue-500">Basso</span>
                                }[task.priorita]
                            }</span></p>
                            <p className="font-300 text-sm ">Data creazione: <span className="font-bold">{moment(task.data_creazione).format('DD/MM/YYYY HH:mm')}</span></p>
                            <p className="font-300 text-sm ">Data modifica: <span className="font-bold">{moment(task.data_modifica).format('DD/MM/YYYY HH:mm')}</span></p>
                            <br />
                            <p onClick={() => {
                                setTasksSelected(task); setModalOpenMessage(true); setTimeout(() => {
                                    goToBottom();
                                }, 300);
                            }} className="cursor-pointer text-gray-700 text-sm pt-8 mt-10 inline">
                                <span className="inline"><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z" />
                                </svg>
                                    <span className="ml-2"></span>
                                </span>
                            </p>
                        </div>

                    )}
                </div>
                <div className="text-gray-700 col-span-6  md:col-span-6 xl:col-span-3  rounded-xl p-6 bg-white	">
                    <h2 className="font-bold text-xl inline">Chiuso </h2>
                    <span className="font-normal inline">({task?.map(task =>
                        task.stato == "chiuso" && (((task.settore && filter.includes(task?.settore?.id)) || filter.length == 0)) &&
                        (filterStato.includes(task.stato) || filterStato.length === 0) && (filterDate.includes(task.id) || filterDate.length === 0)).filter(Boolean).length})
                    </span>
                    {task?.map(task =>
                        (((task.settore && filter.includes(task?.settore?.id)) || filter.length == 0)) &&
                        (filterStato.includes(task.stato) || filterStato.length === 0) && (filterDate.includes(task.id) || filterDate.length === 0) && task.stato == "chiuso" &&
                        <div key={task.id} className="text-gray-700 col-span-12  shadow-md rounded-xl p-4 mt-4 bg-white	" style={{ minHeight: "250px", borderBottom: task.settore ? `6px solid ${task.settore.colore}` : "" }}>
                            <div className="grid grid-cols-12 pt-4 pb-4 w-full">
                                <div className="col-span-12 lg:col-span-6">
                                    <span className="font-bold text-lg">{task.nome}</span>
                                </div>
                                <div className="col-span-12 lg:col-span-6 text-right">
                                    <span className="text-sm font-bold">{
                                        {
                                            "aperto": <span className="whitespace-nowrap	 bg-red-500 text-white text-xs px-4 py-2 rounded-lg">Aperto</span>,
                                            "in_valutazione": <span className="whitespace-nowrap	 bg-yellow-500 text-xs text-white px-4 py-2 rounded-lg">In valutazione</span>,
                                            "in_corso": <span className="whitespace-nowrap	bg-blue-800 text-xs text-white px-4 py-2 rounded-lg">In corso</span>,
                                            "chiuso": <span className="whitespace-nowrap	 bg-green-500 text-xs text-white px-4 py-2 rounded-lg">Chiuso</span>
                                        }[task.stato]
                                    }</span>
                                </div>
                            </div>

                            <p className="font-300 text-md" style={{ minHeight: "15px" }}>{task.descrizione && task.descrizione} </p>
                            <p className="font-300 text-sm mt-4">Aperto da: <b className="capitalize">{task.utente_creato?.username}</b></p>
                            <p className="font-300 text-sm ">Associato a: <b>
                                {task.associato_as.length > 0 ?
                                    task.associato_as.map(associato => <span className="capitalize mr-2">{associato.username}</span>) :
                                    <span className="capitalize mr-2"> {task.associato_as.username}</span>
                                }</b></p>

                            <p className="font-300 text-sm ">Priorità: <span className="font-bold">{
                                {
                                    1: <span className="text-red-600">Alta</span>,
                                    2: <span className="text-yellow-500	">Medio</span>,
                                    3: <span className="text-blue-500">Basso</span>
                                }[task.priorita]
                            }</span></p>
                            <p className="font-300 text-sm ">Data creazione: <span className="font-bold">{moment(task.data_creazione).format('DD/MM/YYYY HH:mm')}</span></p>
                            <p className="font-300 text-sm ">Data modifica: <span className="font-bold">{moment(task.data_modifica).format('DD/MM/YYYY HH:mm')}</span></p>
                            <br />
                            <p onClick={() => {
                                setTasksSelected(task); setModalOpenMessage(true); setTimeout(() => {
                                    goToBottom();
                                }, 300);
                            }} className="cursor-pointer text-gray-700 text-sm pt-8 mt-10 inline">
                                <span className="inline"><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z" />
                                </svg>
                                    <span className="ml-2"></span>
                                </span>
                            </p>
                        </div>
                    )}
                </div>


            </div>
            <Modal
                isOpen={modalOpenMessage}
                contentLabel="Task inserito"
                style={customStylesMessage}
            >
                <div className="text-right p-2">
                    <h2 className="text-xl font-bold mb-6 inline float-left">Commenta ticket</h2>
                    <button className="inline" onClick={() => setModalOpenMessage(false)}><svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 " fill="none" viewBox="0 0 24 24" stroke="black">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                </div>
                <br />
                <div className="flex content-center text-left items-center  h-full">

                    <div className="flex w-full h-full">
                        <div className="pr-4 h-full  w-2/6 shadow p-4 rounded-xl ">
                            <div className="grid grid-cols-12 pt-4 pb-4 w-full ">
                                <div className="col-span-6">
                                    <span className="font-bold text-xl">{taskSelected.nome}</span>
                                </div>
                                <div className="col-span-6 text-right">
                                    <span className="text-sm font-bold heartbeat cursor-pointer">{
                                        {
                                            "aperto": <span onClick={() => { setWindowOpen(!windowOpen) }} className="bg-red-500 text-white px-4 py-2 rounded-lg">Aperto
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                                </svg>
                                            </span>,
                                            "chiuso": <span onClick={() => { setWindowOpen(!windowOpen) }} className="bg-green-500 text-white px-4 py-2 rounded-lg">Chiuso
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                                </svg>
                                            </span>,
                                            "in_valutazione": <span onClick={() => { setWindowOpen(!windowOpen) }} className="bg-yellow-500 text-white px-4 py-2 rounded-lg">In valuta..
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-2 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                                </svg>
                                            </span>,
                                            "in_corso": <span onClick={() => { setWindowOpen(!windowOpen) }} className="whitespace-nowrap	 bg-blue-800 text-white px-4 py-2 rounded-lg">In corso
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-2 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                                </svg>
                                            </span>

                                        }[taskSelected.stato]
                                    }
                                        <span className={windowOpen ? "shadow" : "hidden"} style={{ position: "absolute", textAlign: "left", marginTop: "-10px", marginLeft: "5px", backgroundColor: "white", padding: "15px 10px", borderRadius: "15px" }}>
                                            <button style={{ marginTop: "5px" }} onClick={() => { updateTicket("stato", "aperto") }}>Aperto</button><br></br>
                                            <button style={{ marginTop: "5px" }} onClick={() => { updateTicket("stato", "in_valutazione") }}>In valutazione</button><br></br>
                                            <button style={{ marginTop: "5px" }} onClick={() => { updateTicket("stato", "in_corso") }}>In corso</button><br />
                                            <button style={{ marginTop: "5px" }} onClick={() => { updateTicket("stato", "chiuso") }}>Chiuso</button></span>
                                    </span>
                                </div>
                            </div>

                            <p className="font-300 text-md" style={{ minHeight: "45px" }}>{taskSelected.descrizione && taskSelected.descrizione} </p>
                            <p className="font-300 text-sm ">Associato a:
                                {!editAssociato ?
                                    <>
                                        <b>{taskSelected?.associato_as?.length > 0 ?
                                            taskSelected?.associato_as.map(associato => <span className="capitalize mr-2">{associato.username}</span>) :
                                            <span className="capitalize"> {task?.associato_as?.username}</span>
                                        }</b>
                                        <div onClick={() => setEditAssociato(true)} className=" inline-block">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                            </svg>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <Select
                                            className="custom-select mb-4"
                                            name="associato_a"
                                            options={users?.map(user => {
                                                return {
                                                    value: user.id, label: <div ><img className="w-4 h-4 rounded-full inline mr-2" src={process.env.REACT_APP_BACKEND + user.immagine_profilo?.url} />
                                                        <span className="capitalize">{user.username}</span>
                                                    </div>
                                                }
                                            })}
                                            onChange={(e) => { setAssociatoAsEdit(e); }}
                                            isMulti
                                            components={animatedComponents}
                                        />
                                        <span onClick={() => { updateTicket("associato_as", associatoAs.map(associato => associato.value)) }} className="mt-4 font-300 text-green-600 text-sm border border-green-500 inline pr-4 pl-4 text-sm cursor-pointer" style={{ paddingTop: "5px", paddingBottom: "5px" }}>
                                            Salva</span>

                                    </>
                                }
                            </p>
                            <p className="font-300 text-sm mt-2 mb-2">Aperto da: <b>{taskSelected.utente_creato?.username}</b></p>

                            {editPriorita ?
                                <div className="mt-2 inline-block mb-2">
                                    <span className="font-300 text-sm mt-2 mr-2">Priorità:</span>
                                    <select value={prioritaSelected} onChange={(e) => setPrioritaSelected(e.target.value)} className="bg-white shadow radius-lg text-sm" style={{ minWidth: "80px" }}>
                                        <option value="1" className="text-red-600">Alta</option>
                                        <option value="2" className="text-yellow-500">Medio</option>
                                        <option value="3" className="text-blue-500">Basso</option>
                                    </select>
                                </div>
                                : <span className="font-300 text-sm mt-2">Priorità: <span className="font-bold inline-block">{
                                    {
                                        1: <span className="text-red-600">Alta</span>,
                                        2: <span className="text-yellow-500	">Medio</span>,
                                        3: <span className="text-blue-500">Basso</span>
                                    }[taskSelected.priorita]
                                }</span>
                                </span>}
                            <div onClick={() => { setEditPriorita(!editPriorita); updateTicket("priorita", prioritaSelected) }} className="ml-2 inline">
                                {editPriorita ?
                                    <span className="font-300 text-green-600 text-sm border border-green-500 inline pr-4 pl-4 text-sm cursor-pointer" style={{ paddingTop: "5px", paddingBottom: "5px" }}>
                                        Salva</span>
                                    : <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 cursor-pointer inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                    </svg>}
                            </div>
                            <p className="font-300 text-sm mt-2">Data creazione: <span className="font-bold">{moment(taskSelected.data_creazione).format('DD/MM/YYYY hh:mm')}</span></p>
                            <p className="font-300 text-sm mt-2">Data modifica: <span className="font-bold">{moment(taskSelected.data_modifica).format('DD/MM/YYYY hh:mm')}</span></p>
                        </div>
                        <div className="pl-10 w-4/6" >
                            <div style={{ minHeight: "45vh", maxHeight: "45vh", overflowY: "scroll" }} id="message-box" className="pb-10 pt-2">
                                {messaggiosSelected?.map(messaggio => <div className="mt-4 border shadow m-4  p-4 rounded-xl pb-7">
                                    <p>{messaggio.Testo}</p>
                                    <div className="float-right">
                                        <span className="mr-2 text-gray-600 text-xs">{messaggio.users_permissions_user?.username}</span>
                                        <img alt="avatar" className="w-8 h-8 rounded-full	inline" src={`${process.env.REACT_APP_BACKEND}${messaggio.users_permissions_user?.immagine_profilo.url}`} />
                                    </div>
                                    <p className="text-xs mt-2"></p>
                                </div>
                                )}
                            </div>
                            <div className="flex items-center content-center mt-6 border-t-2 border-gray-100 pt-6	">
                                <textarea value={textMessage} onChange={(e) => setTextMessage(e.target.value)} rows="3" className="shadow-md m p-4 rounded-lg" style={{ minWidth: "92%" }} />
                                <button onClick={insertMessage} className="ml-4 inline p-2 shadow h-full bg-blue-500 text-white rounded-lg font-bold">
                                    Invia</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div >
    );
}

export default Grid;
